import React from "react";
import { auth } from "../../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Message = ({ message }) => {
  const [user] = useAuthState(auth);
  let fch = new Date();
  if( message.createdAt ){
    fch = message.createdAt.toDate();
  }
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "America/Bogota",
  };
  let fc = fch.toLocaleString('es-ES', options);
  return (
    <div
      className={`chat-bubble ${message.uid === user.uid ? "right" : ""}`}>
      <img
        className="chat-bubble__left"
        src={message.avatar}
        alt="user avatar"
      />
      <div className="chat-bubble__right">
      <span className="user-date">{fc}</span>
        <p className="user-name">{message.name} dice:</p>
        <p className="user-message">{message.text}</p>
      </div>
    </div>
  );
};

export default Message;