import React, {useState, useEffect} from 'react';
import { auth } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Header from './components/Header/Header.jsx';
import Home from './components/Home/Home.js';
import ContactForm from './components/Contact/Contact.js';
import About from './components/About/About.jsx';
import Partners from './components/Partners/Partners.js';
import StreamingPlayer from './components/Player/StreamingPlayer.js';
import ChatBox from './components/Chat/Chatbox.js';
import Welcome from './components/Chat/Welcome.js';
import Shows from './components/Shows/Show.js';
import Footer from './components/Footer/Footer.jsx';

import flyr from "./assets/images/rototom2024.jpg";
import flyr2 from "./assets/images/rototom2024.jpg";

import './App.css'

const radioshows = [
  { image: './shows/cmd_soundsystem.jpg', title:'Comunidad Mexicana Del Sound System', alt: "La Comunidad Mexicana Del Sound System es una plataforma dedicada a la difusión de eventos del soundsystem reggae Mexicano y el mundo eventos, flyers, sesiones y mucho dub.", schedule:[{"tipo":"live","dia":"Martes y Sábado", "time":[{"flag":"./flag_col.png","ini":"10:00pm","fin":"12:00am"},{"flag":"./flag_mex.png","ini":"9:00pm","fin":"11:00pm"}]}, {"tipo":"live","dia":"Jueves", "time":[{"flag":"./flag_col.png","ini":"11:00pm","fin":"1:00am"},{"flag":"./flag_mex.png","ini":"10:00pm","fin":"12:00am"}]}, {"tipo":"repeat","dia":"Domingo", "time":[{"flag":"./flag_col.png","ini":"4:00pm","fin":"6:00pm"},{"flag":"./flag_mex.png","ini":"3:00pm","fin":"5:00pm"}]}]},
  { image: './shows/caribbean flow.jpg', title:'Caribbean Flow', alt: "El programa Caribbean Flow semana a semana desde el 2018 nos trae lo nuevo y lo clásico del Dancehall, Reggae, Afrobeat, Soca, Cumbia & Kizomba de la cultura Africaribe. Lo desarrollan la dupla infalible de OneChot y el talentoso productor Dr. Norrys.", schedule:[{"tipo":"live","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"3:00pm","fin":"5:00pm"},{"flag":"./flag_mex.png","ini":"2:00pm","fin":"4:00pm"}]}, {"tipo":"repeat","dia":"Sabado", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}]},
  { image: './shows/logo_wake_up2.png', title:'Wake Up', alt: '"Wake Up" va más allá de la música; es un llamado a la vida con propósito. Jahia Uncan comparte palabras significativas, fusiona ritmos inspiradores y abraza la meditación para despertar cada día con intención. 🌟🎵 #WakeUp', schedule:[{"tipo":"live","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"11:00am"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}, {"tipo":"repeat","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"11:00am"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}]},
  { image: './shows/diaspora.png', title:'Diáspora', alt: 'Programa dedicado a los sonidos de origen afro que han recorrido el mundo generando identidades y múltiples relaciones, acompañados por una charla sobre elementos históricos del proceso diásporico. Dirige DR Tiger', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"9:00pm","fin":"11:00pm"},{"flag":"./flag_mex.png","ini":"8:00pm","fin":"10:00pm"}]}, {"tipo":"repeat","dia":"Sábado", "time":[{"flag":"./flag_col.png","ini":"1:00pm","fin":"3:00pm"},{"flag":"./flag_mex.png","ini":"12:00m","fin":"2:00pm"}]}]},
  { image: './shows/logo_pull_it_up.png', title:'Pull It Up', alt: 'Pull it UP! Radio Show, desde Guadalajara México para el mundo, lo mejor del Reggae, Afrobeats, Dancehall, DUB, artistas invitados cada emisión, siguenos en nuestras distintas redes plataformas digitales y nuestra pagina oficial.', schedule:[{"tipo":"live","dia":"Martes", "time":[{"flag":"./flag_col.png","ini":"7:00pm","fin":"9:00pm"},{"flag":"./flag_mex.png","ini":"6:00pm","fin":"8:00pm"}]}, {"tipo":"repeat","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"12:00m","fin":"2:00pm"},{"flag":"./flag_mex.png","ini":"11:00","fin":"1:00pm"}]}]},
  { image: './shows/logo_living_072023.jpg', title:'Living Ital', alt: "Living Ital es un programa cuyo objetivo es poder transmitir un poco de las vivencias, nyahbinghi, experiencias y livitys Rastafari, asi como música y cultura general de la diáspora. Conducen Yah Joel y Natty Man", schedule:[{"tipo":"live","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"6:00pm","fin":"8:00pm"},{"flag":"./flag_mex.png","ini":"5:00pm","fin":"7:00pm"}]}, {"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"1:00pm","fin":"3:00pm"},{"flag":"./flag_mex.png","ini":"12:00m","fin":"2:00pm"}]}]},
  { image: './shows/logo_legado_africano2.jpg', title:'Legado Africano', alt: 'Legado Africano es un programa de radio que empezó en octubre de 2007 en la emisora de la Universidad Pedagógica y Tecnológica de Colombia en Tunja, Boyacá. Mahavisnu, Andrés y Charli como miembros fundadores de este espacio encuentran el medio para compartir su gusto por el reggae roots, ska, dub y diferentes géneros musicales que hacen parte las formas de expresar los sentimientos sociopolíticos y más profundos cánticos de resistencia conociendo así la historia de áfrica y su diáspora en las antillas, el caribe, el pacifico colombiano enfocados principalmente en la influencia del movimiento Rastafari el territorio.', schedule:[{"tipo":"live","dia":"Jueves", "time":[{"flag":"./flag_col.png","ini":"5:00pm","fin":"7:00pm"},{"flag":"./flag_mex.png","ini":"4:00pm","fin":"6:00pm"}]}, {"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"9:00pm","fin":"11:00pm"},{"flag":"./flag_mex.png","ini":"8:00pm","fin":"10:00pm"}]}]},
  { image: './shows/logo ital morning.png', title:'Ital Morning', alt: '#Italmorning es un programa mañanero elaborado y dirigido por el equipo de RadioItal.com, en donde a traves de conversaciones, se razonan diferentes temas sobre actualidad, educación, misterio, música, baile, entre otras manifestaciones culturales; Angie, Giovanny, Joel y NattyMan junto a invitados especiales acompañarán y brindarán un matiz a tus mañanas.', schedule:[{"tipo":"live","dia":"Jueves y Viernes", "time":[{"flag":"./flag_col.png","ini":"8:00am","fin":"10:00am"},{"flag":"./flag_mex.png","ini":"7:00am","fin":"9:00am"}]}, {"tipo":"repeat","dia":"Domingo", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"11:00am"}]},{"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"8:00am","fin":"10:00am"},{"flag":"./flag_mex.png","ini":"7:00am","fin":"9:00am"}]}]},
  { image: './shows/logo_reggae_colombia.jpg', title:'Reggae Colombia Radio', alt: 'Todos los miércoles sonando lo mejor de la música jamaiquina e internacional. Reggae, raices y cultura, noticias, lanzamientos, actualidades y novedades. Presentado por el apasionado coleccionista, historiador, conferencista, promotor, DJ (Radio & Club), investigador, manager y selector cartagenero Alexander Bonilla Silva a.k.a. Alex BoniJAH, fundador y editor de la página web reggaecolombia.com. Alex BoniJAH ha sido presentador oficial de Jamming TV, Reggae Road, Aquí Suena TV. Host de programas de radio en La Mega Cartagena 94.5 FM y 106.0 FM. Cómo Prensa / Media ha cubierto festivales como Reggae Sumfest, Reggae Sunsplash, Rototom Sunsplash, África Unite, Smile Jamaica, Rebel Salute, Cure Fest, Dennis Brown Tribute, Reggae Festival Film, Reggae Month, Reggae On The River, entre otros. Manager de bandas como Alerta Kamarada, Natural Selection & Prince Ranny y Sampling Dub. Coordinador del Jamming Académico, un espacio cultural del Jamming Festival dedicado a conferencias, conversatorios, debates, entrevistas, reseñas y otros contenidos informativos.', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"3:00pm","fin":"5:00pm"},{"flag":"./flag_mex.png","ini":"2:00pm","fin":"4:00pm"}]}, {"tipo":"repeat","dia":"Domingo", "time":[{"flag":"./flag_col.png","ini":"2:00pm","fin":"4:00pm"},{"flag":"./flag_mex.png","ini":"1:00pm","fin":"3:00pm"}]}]},
  { image: './shows/guetto style.png', title:'Guetto Style', alt: 'Guetto Style "Disfruta los Sonidos del Guetto" es un programa online alternativo, el cual funciona como ventana a los artistas emergentes que se quieren dar a conocer y a estos otros artistas que ya tienen una trayectoria formada. Guetto Style es un show de otro planeta en donde podemos disfrutar de la música Reggae, Rap, Hip Hop, Dancehall, Afrobeat, Dub, Salsa, entre otros, y un sin fin de artes no solamente musicales sino todo tipo de arte que quieras dar a conocer.', schedule:[{"tipo":"live","dia":"Jueves", "time":[{"flag":"./flag_col.png","ini":"9:00pm","fin":"11:00pm"},{"flag":"./flag_mex.png","ini":"8:00pm","fin":"10:00pm"}]}, {"tipo":"repeat","dia":"Martes", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"11:00am"}]}]},
  { image: './shows/bibliotecanegra.jpg', title:'Biblioteca Negra Haile Selassie I ONG', alt: 'La Fundación Biblioteca Negra Haile Selassie I ONG con sedes nacionales e internacionales sin ánimo de lucro pero si con ánimo de expansión educando bajo los pilares de La historia del África, Panafricanismo y Cultura Rastafari.', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"6:00pm","fin":"7:00pm"},{"flag":"./flag_mex.png","ini":"5:00pm","fin":"6:00pm"}]}]},
  { image: './shows/frecuencia omega.jpg', title:'Frecuencia Omega', alt: 'Frecuencia omega es un espacio radial  que permite lanzarnos hacia un viaje en las voces femeninas que han inspirado y hecho parte de la música Reggae, Dub, Steppa alrededor del mundo, también dando visibilidad a nuevos talentos y generando conciencia en la importancia de los espacios culturales pensados para la mujer.', schedule:[{"tipo":"live","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"7:00pm","fin":"8:00pm"},{"flag":"./flag_mex.png","ini":"6:00pm","fin":"7:00pm"}]}, {"tipo":"repeat","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"11:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"10:00am","fin":"11:00am"}]}]},
  { image: './shows/logo_tardes_de_scratch.jpg', title:'Tardes de scratchs con Deejay Mook', alt: 'Todos los miércoles nos vemos de 5 a 6 pm con un programa dedicado a la cultural rapera , y a los músicos en general , un espacio donde compartimos con artistas , se hacen secciones de instrumentales en vivo , se cuentan pequeñas reseñas sobre la importancia de la lectura y además, de compartir conocimiento respecto a la música , producción musical y información sobre encuentros y convocatorias en el país , gracias por sintonizarnos los queremos muchísimo , saludos a tod@s !!', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"5:00pm","fin":"6:00pm"},{"flag":"./flag_mex.png","ini":"4:00pm","fin":"5:00pm"}]}]},
  { image: './shows/sociedad_del_oceano.jpg', title:'La Sociedad del Océano', alt: 'En este espacio nos acercaremos al gran azul a través de la magia de la música, tendremos invitados y hablaremos de temas de interés ambiental y cultural. Conectate y siente la inmensidad del Océano.', schedule:[{"tipo":"live","dia":"Martes", "time":[{"flag":"./flag_col.png","ini":"6:00pm","fin":"7:00pm"},{"flag":"./flag_mex.png","ini":"5:00pm","fin":"6:00pm"}]}]},
  { image: './shows/dr_ital.jpg', title:'DR. Ital', alt: '¡Bienvenidos a "Dr. Ital"! Únete a nosotros en este emocionante viaje a través de la historia y el derecho, mientras exploramos los eventos y figuras que han dado forma al mundo que conocemos hoy. Acompañados por la mejor música, te sumergiremos en fascinantes relatos y análisis de momentos clave que han marcado la humanidad. No te pierdas este programa radial único que combina conocimiento y entretenimiento. ¡Sintoniza "Dr. Ital" y prepárate para un viaje inolvidable!', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"9:00am","fin":"10:00am"},{"flag":"./flag_mex.png","ini":"8:00am","fin":"9:00am"}]}]},
];

//require('dotenv').config();


const App = () => {
  const [user] = useAuthState(auth);
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handleFormOpen = (e) => {
    window.location.assign(
      'https://docs.google.com/forms/d/1QleGnlO9pf0rrqFfyaSZaqIFQyzfG-iEzGaDhJEe2LM/viewform',
    );
    //window.location.replace('https://docs.google.com/forms/d/1QleGnlO9pf0rrqFfyaSZaqIFQyzfG-iEzGaDhJEe2LM/viewform');
  };
  const handleModalClose = (e) => {
    const currentClass = e.target.className;
    setShow(false);
  };
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // Cambiar 768 al ancho deseado para el límite entre móvil y tablet
    };

    // Llamada inicial a handleResize para configurar el estado inicial
    handleResize();

    // Agregar un listener de evento para cambios de tamaño de pantalla
    window.addEventListener('resize', handleResize);

    // Limpieza del listener de evento al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Router>
      <div className="app">
        <div hidden={!show}>
          <div
            className="modal-background"
          >
            <div className="modal-card">
              <img src={isMobile ? flyr2 : flyr} alt="Placeholder" />
              <div className='buttons_modal'>
                <button
                  className="button"
                  onClick={handleModalClose}  
                >
                  Cerrar
                </button>{
                  /*
                }
                <button
                  className="button"
                  onClick={handleFormOpen}  
                >
                  Registro de Emprendimientos
                </button>
                {*/}
              </div>
            </div>
          </div>
        </div>
        <Header />
        <StreamingPlayer streamUrl="https://cast4.my-control-panel.com/proxy/radioita/stream" shows={radioshows}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact" element={<ContactForm />} />
          {/* Add more routes and components for other pages */}
        </Routes>
        {!user ? <Welcome /> : <ChatBox />}
        <Shows slides={radioshows}/>
        <Footer/>
      </div>
    </Router>
  );
};

createRoot(document.getElementById('root')).render(<App />);

export default App;