import React from 'react';
import SliderShow from './SliderShow';
import './Show.css';



const Show = ({slides}) => {
    return (
        <div className="shows-page">
            <div className="shows-content">
                <span className="shows-title">RADIO SHOWS</span>
                <div className="shows-chat">
                    <SliderShow shows={slides} autoSlideDelay={3000}/>
                </div>
            </div>
        </div>
    );
};

export default Show;
