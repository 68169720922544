import React from 'react';
import Slider from './Slider';
import './HomePage.css';


const HomePage = () => {
  
  const slides = [
    { image: './showcase/ital007.jpg', alt: 'Radioital 007' },
    { image: './showcase/ital008.jpg', alt: 'Radioital 008' },
    { image: './showcase/ital009.jpg', alt: 'Radioital 009' },
    { image: './showcase/ital012.jpg', alt: 'Radioital 012' },
    { image: './showcase/ital013.jpg', alt: 'Radioital 013' },
    { image: './showcase/ital014.jpg', alt: 'Radioital 014' },
    { image: './showcase/ital015.jpg', alt: 'Radioital 015' },
    { image: './showcase/ital016.jpg', alt: 'Radioital 016' },
    { image: './showcase/ital017.jpg', alt: 'Radioital 017' },
    { image: './showcase/ital040.jpg', alt: 'Radioital 040' },
    { image: './showcase/ital041.jpg', alt: 'Radioital 041' },
    { image: './showcase/ital052.jpg', alt: 'Radioital 052' },
    { image: './showcase/ital_420_2024_1.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_2.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_3.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_4.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_5.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_6.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_7.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_9.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_11.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_12.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_13.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_14.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_15.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_16.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_17.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_18.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_19.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_20.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_21.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_22.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_23.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_24.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_25.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_26.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_27.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_28.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_29.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_30.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_31.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_32.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_33.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_34.jpg', alt: 'Radioital 4/20 2024' },
  ];

  return (
    <div className="home-page">
      <div className="home-content">
        <h2 className='shows-title'>Showcase</h2>
        <div className="home-chat">
          <Slider slides={slides} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
