import React, { useEffect, useRef, useState } from "react";
import GoogleSignin from "../../assets/images/btn_google_signin_dark_pressed_web.png";
import Logo from "../../assets/images/logo_radioital_negro.png";
import { auth, db } from "../../Firebase";
import { GoogleAuthProvider, signInWithRedirect, signInWithPopup } from "firebase/auth";
import { query, getDocs, collection, where, addDoc} from "firebase/firestore";
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';

const Welcome = () => {
  const [toggle, setToggle] = useState("");
  const expand = toggle ? "open" : "";
  const provider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <>
      <Fab className="btnChat" variant="extended" onClick={() => {
          setToggle(!toggle);
      } }>
          <ChatIcon sx={{ mr: 1 }} />
          {!toggle ? "ABRIR CHAT" : "CERRAR CHAT" }
      </Fab>
      <div className={`navBox ${expand} chat-box`}>
        <h3>Chat RadioItal.com</h3>
        <img src={Logo} alt="Radioital logo" width={50} height={50} />
        <p className="txt_ini">Conectate al chat de Radioital.com con tu cuenta de Google!</p>
        <button className="sign-in">
          <img
            onClick={signInWithGoogle}
            src={GoogleSignin}
            alt="sign in with google"
            type="button"
          />
        </button>
      </div>
    </>
  );
};

export default Welcome;