import React from "react";
import './Contact.css';

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Enviar')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { name, email, message } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    console.log(conFom)
  }

  return (
    <div id="contact-page">
      <h2 className='shows-title'>Contact</h2>
      <div id="contact-form" className="container-fluid">
        <div className="head-form mb-3">
          <h3 className="">Si deseas contactarnos puedes dejar tus datos a continuación o si prefieres puedes escribir a nuestros correos según tu necesidad.</h3>
          <ul className="mails">
            <li><h4>Propuestas Artisticas:</h4><p>operations@radioital.com</p></li>
            <li><h4>Eventos:</h4><p>events@radioital.com</p></li>
            <li><h4>PQRS:</h4><p>contactus@radioital.com</p></li>
          </ul>
        </div>
        <form id="form-data" onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Nombres
            </label>
            <input className="form-control" type="text" id="name" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Correo Elecrónico
            </label>
            <input className="form-control" type="email" id="email" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="message">
              Mensaje
            </label>
            <textarea className="form-control" id="message" required />
          </div>
          <button className="btn btn-danger" type="submit">
            {formStatus}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;