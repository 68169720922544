import React from 'react';
import './About.css';
import team from '../../assets/images/radioital_team.jpg';
import mission from '../../assets/images/radioital_mission.jpg';
import vision from '../../assets/images/radioital_vision.jpg';

const About = () => {
  return (
    <div className='about-page'>
      <h2 className='shows-title'>CREW</h2>
      <div className='history'>
        <div className='history-left'>
          <img className="img-responsive" src={team} alt="whoweare"/>
        </div>
        <div className='history-right'>
          <p>Somos una radio colombiana independiente direccionada por las obras y enseñanzas de sus majestades imperiales Empress   Menem Asfaw y Qedamawi Haile Selassie, quienes son la guia para el desarrollo de esta misión. Trabajamos en hermandad promoviendo el amor, el respeto, la unión, el arte, la literatura, la gastronomía, el baile, la música y todos los aspectos del livity Rastafari, la herencia afrodescendiente y de la diáspora.</p>
          <p>Actualmente contamos con mas de 15 programas junto a exclusivas entrevistas e información cultural transmitida desde Colombia, Mexico, Peru, Venezuela y España.  </p>
        </div>
      </div>
      <h2 className='shows-title'>MISSION</h2>
      <div className='mission'>
        <div className='mission-left'>
          <p>Nuestra misión en RadioItal es inspirar, educar y conectar a través de la música afro y la cultura rastafari a la sociedad. Somos una radio colombiana independiente y un faro de autenticidad dedicados a promover el arte, la literatura, el baile, la música y todos los aspectos de la rica herencia afrodescendiente. Creemos en la música como un lenguaje universal que trasciende fronteras y culturas. A través de nuestra frecuencia radial on line , celebramos la diversidad de ritmos que emanan del continente africano y sus influencias en todo el mundo. Además, abrazamos la filosofía del livity rastafari, promoviendo la unidad, la igualdad y respeto.</p>
          <p>Nos esforzamos por proporcionar un espacio donde artistas, pensadores y amantes de la cultura afro puedan compartir sus voces y perspectivas. Nuestra radio es un puente entre el pasado y el presente, entre la tradición y la innovación. A través de programas, entrevistas y eventos especiales, buscamos empoderar a nuestra audiencia con conocimiento, inspiración y un profundo sentido de comunidad y diversidad cultural. Nuestra visión es un mundo donde el legado afrodescendiente brille con el brillo que se merece.</p>
          <p>En RadioItal, la música afro es más que un sonido: es una conexión a nuestras raíces y un camino hacia un futuro más inclusivo y armonioso.</p>
        </div>
        <div className='mission-right'>
          <img className="img-responsive" src={mission} alt="whoweare"/>
        </div>
      </div>
      <h2 className='shows-title'>VISION</h2>
      <div className='vision'>
        <div className='vision-left'>
          <img className="img-responsive" src={vision} alt="whoweare"/>
        </div>
        <div className='vision-right'>
          <p>Como radio independiente colombiana y auto gestante, nuestra visión es ser un punto de referencia global de la música afro y la cultura rastafari, un espacio donde la diversidad y la unidad se entrelazan en armonía. Buscamos concientizar al mundo con radioital , el legado de la diáspora africana y su riqueza cultural. Visualizamos un futuro en el que cada transmisión que emitimos sea un eco de la riqueza de las raíces africanas, un homenaje a la creatividad y a la resiliencia de las comunidades afrodescendientes en todo el mundo. Queremos ser el catalizador de una revolución cultural, un lugar donde el arte, la literatura, el baile, la música y todas las manifestaciones culturales afro y población de habla hispana se fusionen y florezcan.</p>
          <p>Nuestra radio se esfuerza por ser un medio de comunicación y un puente de entendimiento, conectando a las personas a través de las fronteras, culturas y creencias. Queremos contribuir a la construcción de un mundo más inclusivo, donde el livity rastafari sea una base para la unidad, la igualdad y el respeto. Imaginamos un espacio donde artistas, pensadores y oyentes se reúnan para explorar, aprender y celebrar la riqueza de la herencia afrodescendiente. Queremos ser un motor de empoderamiento y un recordatorio constante de la belleza que reside en la diversidad cultural.</p>
          <p>En conclusión , nuestra visión es ser una influencia positiva y transformadora en el mundo, inspirando a las personas a abrazar y celebrar la cultura afrodescendiente, el livity rastafari y la música afro, y así contribuir a la construcción de un mundo más unido y armonioso.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
