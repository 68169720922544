import React from 'react';
import './Partners.css';
//import SliderBrands from './SliderBrands';

const Partners = () => {
  /*const brands = [
    { image: 'https://radioital.com/app/img/slide_1.791b360.jpg', title:'Wake Up', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_2.4baea67.jpg', title:'Diáspora', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_2.4baea67.jpg', title:'Caribbean Flow', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_2.4baea67.jpg', title:'Riddim Town', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_3.946801c.jpg', title:'Legado Africano', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_3.946801c.jpg', title:'Ital Morning', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
    { image: 'https://radioital.com/app/img/slide_3.946801c.jpg', title:'Living Ital', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra nunc neque, at feugiat lacus lobortis vitae. Curabitur volutpat nunc et porttitor auctor. Suspendisse nisi purus, luctus eu semper et, vulputate ac sapien. Suspendisse in.' },
  ];*/
  
  return (
    <div className='partners-page'>
      <h2 className='shows-title'>Partners</h2>
      <div className='content_partners'>
        <div className='body_partners'> 
            <p>Para nosotros a lo largo de estos 13 años, ha sido todo un honor poder compartir con cada una de las personas que han contribuido con el crecimiento de la familia RadioItal</p>
            <p>A continuación te relacionamos nuestros aliados, amigos, artistas y emprendimientos que nos apoyan:</p>
        </div>
        <div className='imgs_partners'>
          <div className='img'>
            <img src='./brands/camaleonico.png' alt='Camaleonico Placas'/>
            <h3>Camaleonico Placas</h3>
          </div>
          <div className='img'>
            <img src='./brands/dreadhome.jpg' alt='DreadHome'/>
            <h3>DreadHome</h3>
          </div>
          <div className='img'>
            <img src='./brands/rotm.jpg' alt='Rasta On The Mission'/>
            <h3>Rasta On The Mission</h3>
          </div>
          <div className='img'>
            <img src='./brands/fuhucha.jpg' alt='Biblio Fuhucha'/>
            <h3>Biblio Fuhucha</h3>
          </div>
          <div className='img'>
            <img src='./brands/refugioinstinto_logo.jpg' alt='Refugio Instinto'/>
            <h3>Refugio Instinto</h3>
          </div>
          <div className='img'>
            <img src='./brands/bibliotecanegra_logo.jpg' alt='Biblioteca Negra Haile Selassie I ONG'/>
            <h3>Biblioteca Negra Haile Selassie I ONG</h3>
          </div>
          <div className='img'>
            <img src='./brands/irielionsfood_logo.jpg' alt='Irie Lions Food'/>
            <h3>Irie Lions Food</h3>
          </div>
          <div className='img'>
            <img src='./brands/rasurban.png' alt='Ras Urban'/>
            <h3>Ras Urban</h3>
          </div>
          <div className='img'>
            <img src='./brands/og_music.jpg' alt='Only Good Music Radio'/>
            <h3>Only Good Music Radio</h3>
          </div>
          <div className='img'>
            <img src='./brands/samplingdub2.png' alt='Sampling Dub'/>
            <h3>Sampling Dub</h3>
          </div>
          {/**<SliderBrands slides={brands} autoSlideDelay={3000}/>**/}
        </div>
      </div>
    </div>
  );
};

export default Partners;
